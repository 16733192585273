import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { ChromeMessageType } from "cross-platform-resources/dist/chrome-extension/ChromeMessage";

import styles from "./InstallExtensionBanner.module.scss";

export const useCheckExtensionInstalled = () => {
  // Effect for sending session to Chrome extension
  const [didInstallExtension, setDidInstallExtension] = useState(true);

  useEffect(() => {
    try {
      chrome.runtime.sendMessage(
        process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID,
        { type: ChromeMessageType.CHECK_EXTENSION_INSTALLED },
        function (response) {
          if (response?.didInstallExtension) {
            setDidInstallExtension(true);
          } else {
            setDidInstallExtension(false);
          }
        }
      );
    } catch (e) {
      setDidInstallExtension(false);
    }
  }, []);

  return didInstallExtension;
};

const InstallExtensionBanner = () => {
  return (
    <React.Fragment>
      <a
        className={clsx(
          styles.root,
          "max-h-[var(--height-navbar-banner)] grow shrink-0"
        )}
        href={`https://chromewebstore.google.com/detail/yoyo-shortcut/${process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.text}>
          ⚠️ It looks like you {"haven't"} installed the Yoyo Shortcut extension
          yet. Click here to install the extension.
        </div>
      </a>
    </React.Fragment>
  );
};

export default InstallExtensionBanner;
