import React, { useState, useEffect } from "react";

import clsx from "clsx";
import Link from "next/link";
import { LuLayoutDashboard } from "react-icons/lu";
import { MdExitToApp } from "react-icons/md";
import { RiAccountCircleLine } from "react-icons/ri";

import useAuth, { signOutWrapper } from "../../utils/Hooks/useAuth";
import CustomPopper from "../CustomPopper";
import HeadBanners from "../HeadBanners";
import MenuButton from "../MenuButton";
import TryForFreeButton from "../TryForFreeButton";
import YoyoLogo from "../YoyoLogo";
import NavbarSmall from "./NavbarSmall";

import styles from "./Navbar.module.scss";

type Props = {
  isFloat?: boolean; // Position of nav wrapper is "fixed", not "sticky"
  isBlendIn?: boolean; // Background transparent

  hidden?: boolean; // Hidden switch (flipper): Navbar will have opacity transition during hidden or shown
  hiddenClass?: string; // ClassName for hidden

  hideTryForFree?: boolean;
};

const Navbar = (props: Props) => {
  // === Artifacts + Effects Combo ===

  const { isAuthenticated } = useAuth({});

  // === Artifacts

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [isSmallScreenMenuOpen, setSmallScreenMenuOpen] = useState(false);
  const [isPopperMenuOpen, setPopperMenuOpen] = useState(false);

  const handleResize = () => {
    setIsSmallScreen(window.matchMedia("(max-width: 767px)").matches);
  };

  const logout = () => {
    signOutWrapper();
  };

  // === Effects

  // Effect for hidden navbar
  useEffect(() => {
    if (props.hidden) {
      setSmallScreenMenuOpen(false);
      setPopperMenuOpen(false);
    }
  }, [props.hidden]);

  // Effect for small screen navbar
  useEffect(() => {
    if (isSmallScreen) {
      setPopperMenuOpen(false);
    } else {
      setSmallScreenMenuOpen(false);
    }
  }, [isSmallScreen]);

  // Effect for setting small screen
  useEffect(() => {
    setIsSmallScreen(window.matchMedia("(max-width: 767px)").matches);
    window.addEventListener("resize", handleResize);

    // Unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // === Renderers

  return (
    <div
      className={clsx(styles["nav-wrapper"], {
        [styles.float]: props.isFloat,
        [styles.blend]: props.isBlendIn,
        [clsx(styles.hidden, props.hiddenClass)]: props.hidden,
      })}
    >
      <HeadBanners />

      {/* Navbar wrapper */}
      <nav className={styles.nav}>
        {/* Small screen navbar */}
        {isSmallScreen === true && (
          <NavbarSmall
            isMenuOpen={isSmallScreenMenuOpen}
            setIsMenuOpen={setSmallScreenMenuOpen}
            isPopperMenuOpen={isPopperMenuOpen}
            {...props}
          />
        )}

        {/* Large screen navbar */}
        {isSmallScreen === false && (
          <React.Fragment>
            {/* Left container */}
            <div className={styles["nav-left"]}>
              <YoyoLogo />
            </div>

            {/* Right container when not authenticated */}
            {!isAuthenticated && (
              <div className={clsx(styles["nav-right"], "gap-2")}>
                <div className={styles.links}>
                  <Link href="/about" className={styles.link}>
                    About Us
                  </Link>

                  <Link href="/pricing" className={styles.link}>
                    Pricing
                  </Link>

                  <Link
                    href="https://guide.yoyoshortcut.com/"
                    target="_blank"
                    className={styles.link}
                  >
                    Guide
                  </Link>
                </div>

                <div className="h-[32px] bg-[--color-divider-light] w-[1px]" />

                <div className="flex">
                  <Link href="/signin" className={clsx(styles.link)}>
                    Sign in
                  </Link>

                  {!props.hideTryForFree && (
                    <TryForFreeButton
                      size="sm"
                      buttonLocation="Navbar - Try For Free Button"
                    />
                  )}
                </div>
              </div>
            )}

            {/* Right container when authenticated */}
            {isAuthenticated && (
              <div className={clsx(styles["nav-right"], "gap-2")}>
                <div className={styles.links}>
                  <Link href="/about" className={styles.link}>
                    About Us
                  </Link>

                  <Link href="/pricing" className={styles.link}>
                    Pricing
                  </Link>

                  <Link
                    href="https://guide.yoyoshortcut.com/"
                    target="_blank"
                    className={styles.link}
                  >
                    Guide
                  </Link>
                </div>

                <div className="h-[32px] bg-[--color-divider-light] w-[1px]" />

                <div className="flex items-center">
                  {/* Menu button */}
                  <CustomPopper
                    open={isPopperMenuOpen}
                    setOpen={setPopperMenuOpen}
                    classes={{
                      popperWrapper: styles["popper-wrapper"],
                      popper: styles.popper,
                    }}
                    placement="bottom-end"
                    //
                    // === Menu icon
                    //
                    icon={({ setAnchorEl }) => (
                      <div className="ml-6">
                        <MenuButton
                          isMenuOpen={isPopperMenuOpen}
                          onMouseEnter={(event) => {
                            setAnchorEl(event.currentTarget);
                            setPopperMenuOpen(true);
                          }}
                        />
                      </div>
                    )}
                    //
                    // === Menu content
                    //
                    popperContent={({ popperStyles }) => (
                      <React.Fragment>
                        <Link
                          href="/board"
                          className={clsx(
                            popperStyles["popper-item"],
                            styles["popper-item-extend"]
                          )}
                        >
                          <div>
                            <LuLayoutDashboard />
                            Home Board
                          </div>
                        </Link>

                        <Link
                          href="/account"
                          className={clsx(
                            popperStyles["popper-item"],
                            styles["popper-item-extend"]
                          )}
                        >
                          <div>
                            <RiAccountCircleLine />
                            Account
                          </div>
                        </Link>

                        <div className={styles.divider} />

                        <a
                          className={clsx(
                            popperStyles["popper-item"],
                            styles["popper-item-extend"]
                          )}
                          onClick={logout}
                        >
                          <div>
                            <MdExitToApp />
                            Sign out
                          </div>
                        </a>
                      </React.Fragment>
                    )}
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
