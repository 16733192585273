import React from "react";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import clsx from "clsx";
import Link from "next/link";

import useAuth, { signOutWrapper } from "../../utils/Hooks/useAuth";
import { useCheckBanners } from "../HeadBanners";
import MenuButton from "../MenuButton";
import TryForFreeButton from "../TryForFreeButton";
import YoyoLogo from "../YoyoLogo";

import styles from "./NavbarSmall.module.css";

type Props = {
  isMenuOpen: boolean;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;

  isPopperMenuOpen: boolean;

  hideTryForFree?: boolean;
};

const NavbarSmall = (props: Props) => {
  // === Artifacts + Effects Combo ===

  const { isAuthenticated } = useAuth({});

  // === Artifacts

  const checkBanners = useCheckBanners();

  const logout = () => {
    signOutWrapper();
  };

  // === Renderers

  return (
    <div className={styles.root}>
      <YoyoLogo hideTextInSmallScreen />

      <ClickAwayListener
        onClickAway={() => {
          props.setIsMenuOpen(false);
        }}
      >
        <div className={styles["nav-right"]}>
          {/* Try for free button */}
          {!isAuthenticated && !props.isMenuOpen && (
            <TryForFreeButton
              className="!mr-0"
              size="sm"
              buttonLocation="NavbarSmall - Try For Free Button"
            />
          )}

          <div className="ml-6">
            <MenuButton
              isMenuOpen={props.isMenuOpen}
              onClick={() => {
                props.setIsMenuOpen(!props.isMenuOpen);
              }}
            />
          </div>

          <div
            style={{
              top: checkBanners.showBanner
                ? "calc(var(--height-navbar-sm) + 32px)"
                : "var(--height-navbar-sm)",

              height: checkBanners.showBanner
                ? "calc(var(--height-app) - var(--height-navbar-sm) - 32px)"
                : "calc(var(--height-app) - var(--height-navbar-sm))",
            }}
            className={clsx(styles.menu, {
              [styles.hide]: props.isMenuOpen === false,
            })}
          >
            <Link href="/about" className={styles.link}>
              About Us
            </Link>

            <Link href="/pricing" className={styles.link}>
              Pricing
            </Link>

            <Link
              href="https://guide.yoyoshortcut.com/"
              target="_blank"
              className={styles.link}
            >
              Guide
            </Link>

            {!props.hideTryForFree && <div className={styles.divider} />}

            {!isAuthenticated && (
              <React.Fragment>
                <Link href="/signin" className={styles.link}>
                  Sign in
                </Link>

                {!props.hideTryForFree && (
                  <TryForFreeButton
                    size="bg"
                    buttonLocation="NavbarSmall - Try For Free Button"
                  />
                )}
              </React.Fragment>
            )}

            {isAuthenticated && (
              <React.Fragment>
                <Link href="/board" className={styles.link}>
                  Home Board
                </Link>

                <Link href="/account" className={styles.link}>
                  Account
                </Link>

                <div className={styles.divider} />

                <a onClick={logout} className={styles.link}>
                  Sign out
                </a>
              </React.Fragment>
            )}
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default NavbarSmall;
