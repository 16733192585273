import React from "react";

import useAuth from "@/utils/Hooks/useAuth";

import InstallExtensionBanner, {
  useCheckExtensionInstalled,
} from "./InstallExtensionBanner";

export function useCheckBanners() {
  const { isAuthenticated } = useAuth({});

  const didInstallExtension = useCheckExtensionInstalled();

  const isShowInstallExtensionBanner = isAuthenticated && !didInstallExtension;

  return {
    showBanner: isShowInstallExtensionBanner,
    showInstallExtensionBanner: isShowInstallExtensionBanner,
  };
}

export default function HeadBanners() {
  const checkBanners = useCheckBanners();

  if (checkBanners.showInstallExtensionBanner) {
    return <InstallExtensionBanner />;
  }

  return null;
}
